import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { colors, percent } from "../../structure/theme"

const Confirmation = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin: auto;
  text-align: center;

  color: ${colors(`valid`)};
  ${breakpoint(`small`)`
  width: ${percent(10 / 12)}; `}
`

export function PaymentSuccessConfirmation ({ children }) {
  return <Confirmation data-testid="payment_success_confirmation_message">{children}</Confirmation>
}

PaymentSuccessConfirmation.propTypes = {
  children: PropTypes.node.isRequired,
}
