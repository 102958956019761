import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { usePaymentContext } from "../payment.provider"
import { percent } from "../../structure/theme"
import { addPaymentSuccessTracking, useViewPageTracking } from "../../thirdParties/gtm/tagManager"
import { useRewriteHistory } from "../../../utils/hooks"
import PaymentSponsorsLazy from "../paymentSponsors/paymentSponsorsLazy.component"
import { Bold } from "../../structure/theme/styles.component"
import { Trans, useTranslation } from "../../translation/translate.component"
import breakpoint from "styled-components-breakpoint"
import usePhoneCostNumber from "../../contact/phoneCostNumber.hook"
import Block from "../../../components/block/block.component"

const Question = styled.div`
  width: 890px;
  max-width: 100%;
  margin: auto;
  text-align: center;

  ${breakpoint(`small`)`
     width: ${percent(10 / 12)};
  `}
`

export function PaymentSuccess ({ location = {}, children }) {
  const { product, removePostPayment } = usePaymentContext()
  const { customerId = ``, impactId = `` } = location.state || {}
  const { t } = useTranslation()
  const phoneCostNumber = usePhoneCostNumber()

  useRewriteHistory()

  useViewPageTracking(`TunnelCommande`, ``, `Tunnel - Confirmation Souscription`, customerId)

  addPaymentSuccessTracking(impactId, product, removePostPayment)

  return (
    <Block>
      {children}
      <PaymentSponsorsLazy />
      <Question>
        <Bold>{t(`common:payment.section_success_question`)}</Bold>
        <br />
        <Trans
          t={t}
          i18nKey="common:payment.section_success_question_text"
          values={{
            serviceNumber: APP_CONFIG.serviceNumber,
            costNumber: phoneCostNumber ? ` (${phoneCostNumber})` : ``,
            schedules: t(APP_CONFIG.schedules.service),
          }}
        />
      </Question>
    </Block>
  )
}

PaymentSuccess.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      customerId: PropTypes.string.isRequired,
      impactId: PropTypes.string.isRequired,
      activationId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  children: PropTypes.func.isRequired,
}
