import React from "react"
import PropTypes from "prop-types"
import { PaymentSuccess } from "./paymentSuccess/paymentSuccess.component"
import { PaymentSuccessTitle } from "./paymentSuccess/paymentSuccessTitle.component"
import { PaymentSuccessConfirmation } from "./paymentSuccess/paymentSuccessConfirmation.component"
import { Trans, useTranslation } from "../translation/translate.component"

export default function PaymentSuccessPage ({ location }) {
  const { email, activationId } = location.state || {}
  const { t } = useTranslation()

  return (
    <PaymentSuccess location={location}>
      <PaymentSuccessTitle>{t(`common:payment.page_payment_success_title`)}</PaymentSuccessTitle>
      <PaymentSuccessConfirmation>
        <p>
          <Trans t={t} i18nKey="common:payment.page_payment_success_confirmation" values={{ email }} />
          {activationId && <Trans t={t} i18nKey="common:payment.page_payment_success_activationId" />}
        </p>
      </PaymentSuccessConfirmation>
    </PaymentSuccess>
  )
}

PaymentSuccessPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      activationId: PropTypes.string,
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
