import React from "react"
import styled from "styled-components"
import { colors, fontSizes } from "../../structure/theme"
import { Image } from "../../../components/images.component"
import { Bold } from "../../structure/theme/styles.component"
import { ButtonLink } from "../../structure/theme/button.component"
import { createParrainageLink } from "../../navigation/navigation.utils"
import { graphql, useStaticQuery } from "gatsby"
import { Trans, useTranslation } from "../../translation/translate.component"

const ImgContainer = styled.div`
  margin-top: 20px;
  margin-bottom: -55px;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const Block = styled.div`
  display: flex;
  width: 100%;
  max-width: 582px;
  align-items: center;
  flex-direction: column;
  background-color: ${colors(`primary`)};
  color: white;
  padding: 20px 20px 0px 20px;
  border-radius: 10px;
  margin-bottom: 80px;
`

const ForYou = styled.div`
  font-size: ${fontSizes(`xxxx_large`)};
  line-height: 1.19;
  text-align: center;
`

const ForGodson = styled.div`
  font-size: ${fontSizes(`x_large`)};
  text-align: center;
  line-height: 1.21;
`

const AroundYou = styled.div`
  font-weight: 900;
  text-align: center;
  line-height: 1.19;
`

const ExternalLink = styled(ButtonLink).attrs({
  textColor: colors(`primary`),
  backgroundColor: `white`,
})`
  text-transform: none;
  margin: 15px 0;
`

export default function PaymentSponsors () {
  const { parrainageImg } = useStaticQuery(query)
  const { t } = useTranslation()
  const parrainageLink = createParrainageLink(APP_CONFIG.parrainage.home, `tunnel_web`, `Parrainage`)

  return (
    <Wrapper>
      <Block data-testid="parrainage_block">
        <ForYou>
          <Trans
            t={t}
            i18nKey="common:payment.section_success_for_you"
            components={{
              bold: <Bold />,
            }}
          />
        </ForYou>
        <ForGodson>
          <Trans
            t={t}
            i18nKey="common:payment.section_success_for_godson"
            components={{
              bold: <Bold />,
            }}
          />
        </ForGodson>
        <ExternalLink href={parrainageLink} target="__blank" data-testid="parrainage_link">
          {t(`common:payment.section_success_external_link`)}
        </ExternalLink>
        <AroundYou>{t(`common:payment.section_success_around_you`)}</AroundYou>
        <ImgContainer>
          <Image file={parrainageImg} />
        </ImgContainer>
      </Block>
    </Wrapper>
  )
}

const query = graphql`
  query {
    parrainageImg: file(name: { eq: "parrainage" }) {
      childImageSharp {
        fixed(width: 210) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
