import { useState, useEffect, useCallback, useRef } from "react"
import _noop from "lodash/noop"
import { routes } from "../modules/navigation/routes"
import { useTranslation } from "../modules/translation/translate.component"
import { useIsMultiLingual } from "../modules/website/locales.hook"
import { usePageContext } from "../modules/navigation/pageContext.provider"

export function useTimeout (callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick () {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setTimeout(tick, delay)

      return () => clearTimeout(id)
    }
  }, [delay])
}

export function useAbortController () {
  if (SERVER) {
    return _noop
  }

  const [controller, setController] = useState(new window.AbortController())

  return useCallback(() => {
    if (controller) {
      controller.abort()
    }

    const newController = new window.AbortController()
    setController(() => newController)

    return newController.signal
  }, [controller])
}

export function useRewriteHistory (route = `home`, params = {}) {
  const { t } = useTranslation()
  const isMultiLingual = useIsMultiLingual()
  const { locale } = usePageContext()
  const getRoute = routes[route]

  useEffect(() => {
    if (getRoute) {
      const currentHref = window.location.href
      window.history.pushState(
        null,
        null,
        APP_CONFIG.basePath + getRoute.createPath(isMultiLingual, t, { locale, ...params }),
      )
      window.history.pushState(null, null, currentHref)
      window.onpopstate = function () {
        window.history.go(2)
      }
    }

    return () => {
      window.onpopstate = _noop
    }
  }, [])
}
