import React from "react"
import PropTypes from "prop-types"
import { Primary } from "../../structure/theme/titles.component"

export function PaymentSuccessTitle ({ children }) {
  return (
    <Primary data-testid={arguments[0][`data-testid`] || undefined} center>
      {children}
    </Primary>
  )
}

PaymentSuccessTitle.propTypes = {
  children: PropTypes.node.isRequired,
}
